import React from "react";
import HeaderPhoto from "../components/HeaderPhoto";
import Layout from "../components/Layout";
import photo from "../images/pitaj.webp";
import * as styles from "../styles/pitaj.module.css";
import uplatnica from '../images/uplatnica.png';

export default function Pitajte() {
  return (
    <Layout>
      <HeaderPhoto title="Pitajte Nutricionistu" photo={photo} />
      <div className={styles.ask}>
        <div className={styles.askText}>
          <p>
            Ukoliko vam je potreban savet ili imate neko pitanje u vezi sa
            ishranom i zdravljem, a niste u mogućnosti da dođete, ovde ga možete
            postaviti. Nutricionista će vam odgovoriti u najkraćem mogućem roku.
          </p>
        </div>
        <div className={styles.askImageWrap}>
          <img
            src={uplatnica}
            alt=""
          />
        </div>
      </div>
    </Layout>
  );
}

export const Head = () => <title>Sonja Nikacevic | Nutricionista</title>;
